exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-expertise-js": () => import("./../../../src/pages/expertise.js" /* webpackChunkName: "component---src-pages-expertise-js" */),
  "component---src-pages-focus-areas-fintech-js": () => import("./../../../src/pages/focus-areas/fintech.js" /* webpackChunkName: "component---src-pages-focus-areas-fintech-js" */),
  "component---src-pages-focus-areas-government-js": () => import("./../../../src/pages/focus-areas/government.js" /* webpackChunkName: "component---src-pages-focus-areas-government-js" */),
  "component---src-pages-focus-areas-healthcare-js": () => import("./../../../src/pages/focus-areas/healthcare.js" /* webpackChunkName: "component---src-pages-focus-areas-healthcare-js" */),
  "component---src-pages-focus-areas-js": () => import("./../../../src/pages/focus-areas.js" /* webpackChunkName: "component---src-pages-focus-areas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index_old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-notice-js": () => import("./../../../src/pages/privacy_notice.js" /* webpackChunkName: "component---src-pages-privacy-notice-js" */),
  "component---src-pages-products-datablueprint-js": () => import("./../../../src/pages/products/datablueprint.js" /* webpackChunkName: "component---src-pages-products-datablueprint-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms_of_service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

